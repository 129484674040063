/* eslint-disable */
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type GetDocumentSummaryQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
}>;


export type GetDocumentSummaryQuery = { __typename?: 'Query', document: { __typename?: 'Document', summary?: { __typename?: 'DocumentSummary', id: string, createdAt: any, updatedAt: any, data: any } | null } };

export const GetDocumentSummaryDocument = gql`
    query GetDocumentSummary($documentId: ID!) {
  document(id: $documentId) {
    summary {
      id
      createdAt
      updatedAt
      data
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDocumentSummaryQueryService extends Query<GetDocumentSummaryQuery, GetDocumentSummaryQueryVariables> {
    document = GetDocumentSummaryDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }