<omg-collapsible-banner variant="instructional"
                        (expandChange)="onExpandChange($event)">
  <div header>Document Summarization is in Beta</div>
  <div severity-title>
    <span *ngIf="bannerThanksTextVisible"
          [@thanksAnimation]
          class="thanks-text">
      ✓ Thank you for your feedback
    </span>
    <span *ngIf="reviewTextVisible">
      Rate this summary
    </span>
  </div>
  <div class="feedback-form"
       message>
    <form [formGroup]="feedbackForm">
      <omg-button-group class="feedback-options"
                        [buttonGroupControl]="feedbackForm.get('feedbackResponse')"
                        [options]="feedbackOptions"></omg-button-group>
      <div class="freetext-feedback-section"
           *ngIf="feedbackForm.get('feedbackResponse').value">
        <label>Tell us why
          <textarea class="feedback-text"
                    omgTextarea></textarea>
        </label>
      </div>
    </form>
    <div class="action-bar">
      <button omgButton
              class="submit-button"
              type="button"
              [disabled]="!feedbackForm.get('feedbackResponse').value"
              variant="primary"
              (click)="onSubmit()">
        Submit
      </button>
      <button omgButton
              class="cancel-button"
              type="button"
              variant="flat"
              (click)="onCancel()">
        Cancel
      </button>
    </div>
  </div>
</omg-collapsible-banner>
