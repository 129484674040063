import { Component, Input } from '@angular/core';

import { SmartDraftService } from '@app/modules/messaging/shared/smart-draft.service';

import { SmartDraftFeedback } from '../../../../../graphql/onelife.type';
import {
  DraftInfoFragment,
} from '@app/modules/messaging/shared/smart-draft.onelife.generated';

@Component({
  selector: 'omg-smart-draft-feedback-banner',
  templateUrl: './smart-draft-feedback-banner.html',
  styleUrls: ['./smart-draft-feedback-banner.component.scss'],
})
export class SmartDraftFeedbackBannerComponent  {
  @Input() smartDraft: DraftInfoFragment;
  constructor(private smartDraftService: SmartDraftService) {}


  saveFeedback(newFeedback: SmartDraftFeedback) {
    this.smartDraftService.saveFeedback(
      this.smartDraft.id,
      newFeedback,
    ).subscribe();
  }

  get isUpvote(): boolean {
    return this.smartDraft?.feedback === SmartDraftFeedback.Upvote;
  }
  get isDownvote(): boolean {
    return this.smartDraft?.feedback === SmartDraftFeedback.Downvote;
  }

  protected readonly SmartDraftFeedback = SmartDraftFeedback;
}
