import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import { CollapsibleBannerComponent } from '@app/shared/components/collapsible-banner/collapsible-banner.component';

@Component({
  selector: 'omg-document-summary-feedback',
  templateUrl: './document-summary-feedback.component.html',
  styleUrls: ['./document-summary-feedback.component.scss'],
  animations: [
    trigger('thanksAnimation', [
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('0.5s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class DocumentSummaryFeedbackComponent {
  feedbackForm: UntypedFormGroup;
  bannerThanksTextVisible: boolean = false;
  reviewTextVisible: boolean = true;

  @ViewChild(CollapsibleBannerComponent) banner: CollapsibleBannerComponent;

  constructor(private formBuilder: UntypedFormBuilder) {
    const feedbackResponse = new UntypedFormControl();
    this.feedbackForm = this.formBuilder.group({
      feedbackResponse,
    });
  }

  onSubmit() {
    this.bannerThanksTextVisible = true;
    this.reviewTextVisible = false;
    this.banner.collapse();
    setTimeout(() => {
      this.bannerThanksTextVisible = false;
      setTimeout(() => {
        this.reviewTextVisible = true;
      }, 550);
    }, 2000);
  }

  onCancel() {
    this.banner.collapse();
  }

  onExpandChange(expanded: boolean) {
    if (!expanded) {
      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.feedbackForm.reset({
      feedbackResponse: null,
    });
  }

  feedbackOptions = [
    {
      label: 'The summary is accurate and I found it useful',
      value: '0',
    },
    {
      label: 'The summary is accurate, but I did not find it useful',
      value: '1',
    },
    {
      label: 'The summary seems inaccurate',
      value: '2',
    },
  ];
}
